import { UseQueryResult } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";
import { RoutePaths } from "src/pages/routePaths";
import { MeResponse } from "src/pages/SignIn/api/intefaces";

interface GuestRouteProps {
  currentUser: UseQueryResult<MeResponse, Error>;
}

const GuestRoute = ({ currentUser }: GuestRouteProps) => {
  const token = localStorage.getItem("token");
  const { data } = currentUser;

  if (token && data) {
    return <Navigate to={RoutePaths.Overview} replace />;
  }

  return <Outlet />;
};

export default GuestRoute;
